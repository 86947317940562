import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsideBannerComponent } from '@components/aside-banner/aside-banner.component';
import { LoginFormComponent } from '@components/login-form/login-form.component';

@Component({
  selector: 'app-aside-layout',
  standalone: true,
  imports: [RouterOutlet, AsideBannerComponent, LoginFormComponent],
  templateUrl: './aside-layout.component.html',
  styleUrl: './aside-layout.component.scss',
})
export class AsideLayoutComponent {}
