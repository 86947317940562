import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { ErrorService } from '@services/error/error.service';
import { QUERY_PARAMS } from '@constants/query-params.const';
import { firstValueFrom } from 'rxjs';
import { PasswordService } from '@services/password/password.service';

export const validateResetPasswordTokenGuard: CanActivateFn = async () => {
  const queryParamsService = inject(QueryParamsService);
  const passwordService = inject(PasswordService);
  const errorService = inject(ErrorService);

  const resetToken = queryParamsService.get(QUERY_PARAMS.RESET_PASSWORD_TOKEN);

  try {
    await firstValueFrom(passwordService.checkResetToken(resetToken));
  } catch (e) {
    return errorService.getExpiredLinkPageUrlTree();
  }

  return true;
};
