import { Routes } from '@angular/router';
import { createRequiredParamsGuard } from '@guards/create-required-params.can-activate-fn';
import { ERROR_PAGE_DESCRIPTION } from './pages/error/error-page-description.token';
import { validateInvitationTokenGuard } from '@guards/validate-invitation-token.can-activate-fn';
import { validateResetPasswordTokenGuard } from '@guards/validate-reset-password-token.can-activate-fn';
import { REGISTER_REQUIRED_PARAMS } from './pages/register/register-required-params.const';
import { LOGIN_REQUIRED_PARAMS } from './pages/login/login-required-params.const';
import { RESET_PASSWORD_REQUIRED_PARAMS } from './pages/reset-password/reset-password-required-params.const';
import { AsideLayoutComponent } from '@components/aside-layout/aside-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: AsideLayoutComponent,
    children: [
      {
        path: 'login',
        loadComponent: () => import('./pages/login/login.component').then((mod) => mod.LoginComponent),
        canActivate: [createRequiredParamsGuard(LOGIN_REQUIRED_PARAMS)],
        title: 'log_in.title',
      },

      {
        path: 'register',
        loadComponent: () => import('./pages/register/register.component').then((mod) => mod.RegisterComponent),
        canActivate: [createRequiredParamsGuard(REGISTER_REQUIRED_PARAMS), validateInvitationTokenGuard],
        title: 'register.title',
      },

      {
        path: 'reset-password',
        loadComponent: () =>
          import('./pages/reset-password/reset-password.component').then((mod) => mod.ResetPasswordComponent),
        canActivate: [createRequiredParamsGuard(RESET_PASSWORD_REQUIRED_PARAMS), validateResetPasswordTokenGuard],
        title: 'reset_password.title',
      },

      {
        path: 'go-to-app',
        loadComponent: () => import('./pages/onboarding/onboarding.component').then((mod) => mod.OnboardingComponent),
        title: 'onboarding.download_app',
      },

      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/error',
      },
    ],
  },

  {
    path: 'link-expired',
    loadComponent: () => import('./pages/error/error.component').then((mod) => mod.ErrorComponent),
    providers: [{ provide: ERROR_PAGE_DESCRIPTION, useValue: 'errors.invitation_link_expired' }],
    title: 'error.title',
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/error/error.component').then((mod) => mod.ErrorComponent),
    providers: [{ provide: ERROR_PAGE_DESCRIPTION, useValue: 'errors.unexpected' }],
    title: 'error.title',
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];
