import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { ErrorService } from '@services/error/error.service';
import { RegistrationService } from '@services/registration/registration.service';
import { QUERY_PARAMS } from '@constants/query-params.const';
import { firstValueFrom } from 'rxjs';

export const validateInvitationTokenGuard: CanActivateFn = async () => {
  const queryParamsService = inject(QueryParamsService);
  const registrationService = inject(RegistrationService);
  const errorService = inject(ErrorService);

  const invitationToken = queryParamsService.get(QUERY_PARAMS.INVITATION_TOKEN);

  try {
    await firstValueFrom(registrationService.checkInvitationToken(invitationToken));
  } catch (e) {
    return errorService.getExpiredLinkPageUrlTree();
  }

  return true;
};
