import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { ErrorService } from '@services/error/error.service';

export const createRequiredParamsGuard = (requiredParams: string[]): CanActivateFn => {
  return () => {
    const queryParamsService = inject(QueryParamsService);
    const errorService = inject(ErrorService);

    if (requiredParams.every((param) => queryParamsService.has(param))) {
      return true;
    }

    return errorService.getErrorPageUrlTree();
  };
};
