<div class="wrapper">
  <div class="content-wrapper">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="banner-wrapper">
    <app-aside-banner class="banner" />
  </div>
</div>
