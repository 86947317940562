export const joinUrls = (...urls: string[]): string => {
  let url = '';
  urls.forEach((u) => {
    if (u.startsWith('/')) {
      url = url.concat(u);
    } else if (u.startsWith('http')) {
      url = url.concat(u);
    } else {
      url = url.concat(url.endsWith('/') ? u : '/' + u);
    }
  });

  return url;
};
