import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE } from '@constants/default-locale.const';
import { QUERY_PARAMS } from '@constants/query-params.const';

export const APP_INITIALIZERS: (Provider | EnvironmentProviders)[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [QueryParamsService, TranslateService],
    useFactory: (queryParams: QueryParamsService, translate: TranslateService) => {
      const fallbackLocale = translate.getBrowserLang() || DEFAULT_LOCALE;

      return () => {
        translate.use(queryParams.get(QUERY_PARAMS.LANG, fallbackLocale));
      };
    },
  },
];
