import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DEFAULT_LOCALE } from '@constants/default-locale.const';
import { APP_INITIALIZERS } from './app.initializers';
import { APP_INTERCEPTORS } from './app.interceptors';
import { APP_PROVIDERS } from './app.providers';

const APP_TRANSLATE_MODULE = TranslateModule.forRoot({
  defaultLanguage: DEFAULT_LOCALE,
  loader: {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
    deps: [HttpClient],
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    ...APP_INITIALIZERS,
    ...APP_INTERCEPTORS,
    ...APP_PROVIDERS,
    provideRouter(routes),
    importProvidersFrom(APP_TRANSLATE_MODULE, HttpClientModule),
  ],
};
